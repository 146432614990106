import { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import Form from "react-bootstrap/Form";
import Carousel from "react-bootstrap/Carousel";
import Grid from "@mui/material/Grid2";
import { Box, CircularProgress, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

import {
  usePostGoogleLoginMutation,
  usePostRegisterMutation,
} from "../../Redux/api/User";

import NavBar from "../Navbar";

import { homeRoute } from "../../Routes/constants";

import { generateHash } from "../../Utils/generatehash";

import larlogo from "../../assets/images/signup_graphics/logo-beta.png";
import img1 from "../../assets/images/signup_graphics/financial _tracking.png";
import img2 from "../../assets/images/signup_graphics/expensetracker.png";
import img3 from "../../assets/images/signup_graphics/pricingdiscovery.png";
import img4 from "../../assets/images/signup_graphics/analyticsandoptimization.png";

import {
  LeftPanel,
  CarouselBox,
  RightPanel,
  LoginForm,
  NavbarBox,
  OptionTextStyle,
  StyledLogoBox,
  StyledImg,
} from "./styles";
import { StyleButton } from "../../Common/styles";
import "bootstrap/dist/css/bootstrap.min.css";

const Register = () => {
  const navigate = useNavigate();

  const [index, setIndex] = useState(0);
  const [regIndex, setRegIndex] = useState(0);
  const [passwordValidation, setPasswordValidation] = useState({
    lowercase: false,
    uppercase: false,
    number: false,
    length: false,
  });

  const [register, { isLoading: isAdding }]: any = usePostRegisterMutation();
  const [googlelogin]: any = usePostGoogleLoginMutation();

  const regexpLowercase = new RegExp("(?=.*[a-z])");
  const regexpUppercase = new RegExp("(?=.*[A-Z])");
  const regexpNumber = new RegExp("(?=.*[0-9])");

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  const validationSchema = [
    yup.object({
      firstName: yup.string().required("First name is required"),
      lastName: yup.string().required("Last name is required"),
      email: yup
        .string()
        .email("Please enter valid email Id")
        .required("Email is required"),
    }),
    yup.object({
      busiName: yup.string().required("Business name is required"),
      busiLink: yup
        .string()
        .url("Please enter valid website link")
        .required("Business website link is required"),
      type: yup.string().required("Business type is required"),
    }),
    yup.object({
      password: yup
        .string()
        .required("Password is required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,}$/,
          "Password must include lowercase, uppercase, number, and be at least 6 characters"
        ),
      confirmpassword: yup
        .string()
        .required("Confirm password is required")
        .test("passwords-match", "Passwords must match", function (value) {
          const { password } = this.parent;
          return value === password;
        }),
    }),
  ];

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      busiName: "",
      busiLink: "",
      password: "",
      confirmpassword: "",
      type: "",
      termsAcceptedBuy: false,
    },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: validationSchema[regIndex],
    onSubmit: async (values) => {
      const { firstName, lastName, email, password, busiLink, busiName } =
        values;
      if (!formik.isValid && regIndex < 2) {
        return;
      }
      try {
        await register({
          firstName,
          lastName,
          email,
          password,
          company: busiName,
          link: busiLink,
        });

        navigate(homeRoute);
      } catch (err) {
        toast.error("Email is already exist");
      }
    },
  });
  const {
    values,
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldTouched,
  } = formik;

  const handleCheckPassword = (password: string) => {
    setPasswordValidation({
      lowercase: regexpLowercase.test(password),
      uppercase: regexpUppercase.test(password),
      number: regexpNumber.test(password),
      length: password.length >= 6,
    });
  };

  const responseGoogle = async (response: any) => {
    const creds = response.credential;
    if (creds) {
      try {
        const userInfo: any = jwtDecode(creds);
        const payload: any = {
          email: userInfo.email,
          googleId: userInfo.sub,
          firstName: userInfo.given_name,
          lastName: userInfo.family_name,
          log_token: generateHash(userInfo),
        };
        const {
          data: { token },
        } = await googlelogin(payload);
        localStorage.setItem("token", token);

        navigate(homeRoute);
      } catch (err) {}
    }
  };

  return (
    <>
      <div>
        <Grid container spacing={0} sx={{ height: "100vh" }}>
          <Grid size={{ xs: 0, sm: 0, md: 7, lg: 7 }}>
            <LeftPanel>
              <StyledLogoBox>
                <a href="https://www.sotira.co/">
                  <StyledImg alt="logo" src={larlogo} />
                </a>
              </StyledLogoBox>
              <Box>
                <CarouselBox>
                  <Carousel
                    pause="hover"
                    className="carousel-content"
                    bsPrefix="carousel"
                    indicators={false}
                    fade={true}
                    nextLabel={null}
                    prevLabel={null}
                    slide={false}
                    activeIndex={index}
                    onSelect={handleSelect}
                  >
                    <Carousel.Item>
                      <Box className="img-wrapper">
                        <img src={img1} alt="First slide" />
                      </Box>
                      <Carousel.Caption>
                        <h5>
                          Discover how much cash you can unlock for your excess
                          inventory
                        </h5>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <Box className="img-wrapper">
                        <img src={img2} alt="Second slide" />
                      </Box>
                      <Carousel.Caption>
                        <h5>Connect with a vetted network of private buyers</h5>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <Box className="img-wrapper">
                        <img src={img3} alt="Third slide" />
                      </Box>
                      <Carousel.Caption>
                        <h5>
                          Convert dead inventory to working capital within a
                          week
                        </h5>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <Box className="img-wrapper">
                        <img src={img4} alt="Fourth slide" />
                      </Box>
                      <Carousel.Caption>
                        <h5>
                          Assess and compare profitable and efficient offloading
                          options
                        </h5>
                      </Carousel.Caption>
                    </Carousel.Item>
                  </Carousel>
                </CarouselBox>
              </Box>
            </LeftPanel>
          </Grid>
          <Grid size={{ xs: 12, sm: 12, md: 5, lg: 5 }}>
            {/* this is the side Login */}

            <RightPanel>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "20px",
                }}
              >
                <StyledImg alt="logo" src={larlogo} />
              </Box>
              <LoginForm>
                <NavbarBox>
                  <NavBar />
                </NavbarBox>
                <h2 className="titleLog"> Sign Up </h2>
                <Form className="signinform" onSubmit={handleSubmit}>
                  <Form.Group className="form-field">
                    <GoogleLogin
                      size="large"
                      width="170"
                      onSuccess={responseGoogle}
                      onError={() => {
                        console.log("Login Failed");
                      }}
                    />
                  </Form.Group>
                  {regIndex === 0 && (
                    <Box>
                      <Form.Group className="form-field">
                        <Form.Label className="title">First Name</Form.Label>
                        <Form.Control
                          name="firstName"
                          autoFocus
                          type="text"
                          value={values.firstName}
                          placeholder="Enter first name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.firstName && !!errors.firstName}
                        />
                        {touched.firstName && errors.firstName && (
                          <Form.Control.Feedback type="invalid">
                            {errors.firstName}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                      <Form.Group className="form-field">
                        <Form.Label className="title">Last Name</Form.Label>
                        <Form.Control
                          name="lastName"
                          type="text"
                          value={values.lastName}
                          placeholder="Enter last name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.lastName && !!errors.lastName}
                        />
                        {touched.lastName && errors.lastName && (
                          <Form.Control.Feedback type="invalid">
                            {errors.lastName}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                      <Form.Group className="form-field">
                        <Form.Label className="title">Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          value={values.email}
                          placeholder="Enter email address"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.email && !!errors.email}
                        />
                        {touched.email && errors.email && (
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>

                      <StyleButton
                        type="submit"
                        onClick={() => formik.isValid && setRegIndex(1)}
                      >
                        Next
                      </StyleButton>
                    </Box>
                  )}
                  {regIndex === 1 && (
                    <Box>
                      <Form.Group className="form-field">
                        <Form.Label className="title">Business Name</Form.Label>
                        <Form.Control
                          autoFocus
                          name="busiName"
                          type="text"
                          value={values.busiName}
                          placeholder="Enter business name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.busiName && !!errors.busiName}
                        />
                        {touched.busiName && errors.busiName && (
                          <Form.Control.Feedback type="invalid">
                            {errors.busiName}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                      <Form.Group className="form-field">
                        <Form.Label className="title">
                          Business Website
                        </Form.Label>
                        <Form.Control
                          type="busiLink"
                          name="busiLink"
                          value={values.busiLink}
                          placeholder="Enter website name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.busiLink && !!errors.busiLink}
                        />
                        {touched.busiLink && errors.busiLink && (
                          <Form.Control.Feedback type="invalid">
                            {errors.busiLink}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>

                      <Form.Group className="form-field">
                        <Form.Label className="title">Business Type</Form.Label>
                        <Form.Control
                          as="select"
                          value={values.type}
                          name="type"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.type && !!errors.type}
                          style={{ appearance: "auto" }}
                        >
                          <option value="">Select Business Type</option>
                          <option value="buyer">Buyer</option>
                          <option value="seller">Seller</option>
                          <option value="buyer-seller">Buyer and Seller</option>
                        </Form.Control>
                        {touched.type && errors.type && (
                          <Form.Control.Feedback type="invalid">
                            {errors.type}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <StyleButton onClick={() => setRegIndex(0)}>
                          Back
                        </StyleButton>

                        <StyleButton
                          type="submit"
                          onClick={() => formik.isValid && setRegIndex(2)}
                        >
                          Next
                        </StyleButton>
                      </Box>
                    </Box>
                  )}
                  {regIndex === 2 && (
                    <Box>
                      <Form.Group className="form-field">
                        <Form.Label className="title">Password</Form.Label>

                        <Form.Control
                          type="password"
                          name="password"
                          value={values.password}
                          onChange={(e) => {
                            setFieldValue("password", e.target.value);
                            handleCheckPassword(e.target.value);
                          }}
                          onClick={() => setFieldTouched("password", true)}
                        />
                        {touched.password && (
                          <Box sx={{ padding: "2px 0px" }}>
                            {[
                              {
                                label: "Lowercase letter",
                                valid: passwordValidation.lowercase,
                              },
                              {
                                label: "Uppercase letter",
                                valid: passwordValidation.uppercase,
                              },
                              {
                                label: "Number",
                                valid: passwordValidation.number,
                              },
                              {
                                label: "Minimum 6 characters",
                                valid: passwordValidation.length,
                              },
                            ].map((validation, index) => (
                              <Box
                                key={index}
                                sx={{ display: "flex", gap: "5px" }}
                              >
                                {validation.valid ? (
                                  <CheckIcon
                                    sx={{ color: "green" }}
                                    fontSize="small"
                                  />
                                ) : (
                                  <CloseIcon
                                    sx={{ color: "red" }}
                                    fontSize="small"
                                  />
                                )}
                                {validation.label}
                              </Box>
                            ))}
                          </Box>
                        )}
                      </Form.Group>
                      <Form.Group className="form-field">
                        <div className="d-flex justify-content-between">
                          <Form.Label className="title">
                            Confirm Password
                          </Form.Label>
                          {touched.confirmpassword && (
                            <IconButton>
                              {touched.confirmpassword &&
                              errors.confirmpassword ? (
                                <CloseIcon
                                  sx={{ color: "red" }}
                                  fontSize="small"
                                />
                              ) : (
                                <CheckIcon
                                  sx={{ color: "green" }}
                                  fontSize="small"
                                />
                              )}
                            </IconButton>
                          )}
                        </div>
                        <Form.Control
                          type="password"
                          name="confirmpassword"
                          value={values.confirmpassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Form.Group>

                      <Form.Group className="form-field">
                        <Form.Check
                          type="checkbox"
                          label={
                            <>
                              I have read and accept the{" "}
                              <a href="https://www.sotira.co/terms-of-service">
                                terms of service
                              </a>
                            </>
                          }
                          checked={values.termsAcceptedBuy}
                          onChange={(e) =>
                            setFieldValue("termsAcceptedBuy", e.target.checked)
                          }
                        />
                      </Form.Group>
                      <Box
                        sx={{
                          maxWidth: "305px",
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <StyleButton onClick={() => setRegIndex(1)}>
                          Back
                        </StyleButton>

                        <StyleButton
                          type="submit"
                          disabled={isAdding}
                          endIcon={
                            isAdding ? (
                              <CircularProgress
                                size={18}
                                sx={{ color: "#48b7a7" }}
                              />
                            ) : null
                          }
                        >
                          Sign Up
                        </StyleButton>
                      </Box>
                    </Box>
                  )}

                  <OptionTextStyle>
                    Already have an account?
                    <a
                      style={{ color: "#89D0C6", textDecoration: "none" }}
                      href={homeRoute}
                    >
                      &nbsp;Sign in here
                    </a>
                  </OptionTextStyle>
                </Form>
              </LoginForm>
            </RightPanel>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default Register;
