export const generateHash = async (userInfo: any) => {
  // Create a string from the user information
  const GOOGLE_LOGIN_HASH_KEY = "snJ5K#WagH2Zvkg4";
  const text = new TextEncoder().encode(
    `${GOOGLE_LOGIN_HASH_KEY}${userInfo.sub}${userInfo.email}${userInfo.given_name}`
  );

  try {
    // Use the Web Crypto API to generate a SHA-256 hash
    const hashBuffer = await crypto.subtle.digest("SHA-256", text);

    // Convert the ArrayBuffer to a hex string
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((byte) => byte.toString(16).padStart(2, "0")).join("");
  } catch (error) {}
};
