import { ListItemText, styled } from "@mui/material";

export const NavBarItemText = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== "isSignUp",
})(({ isSignUp }: { isSignUp: boolean }) => ({
  color: isSignUp ? "#FFF" : "#000",
  backgroundColor: isSignUp ? "#000" : "#fff",
  fontWeight: "bold",
  fontSize: "16px",
  textAlign: "center",
  padding: isSignUp ? "10px" : "10px 18px",
  marginLeft: isSignUp ? "8px" : "0px",
  "&:hover": {
    backgroundColor: "#000",
    color: "#fff",
  },
}));
