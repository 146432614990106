import { Box, styled } from "@mui/material";

import "bootstrap/dist/css/bootstrap.min.css";

export const RightPanel = styled(Box)`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const LeftPanel = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  background-color: #dff5e4;
  @media (max-width: 899px) {
    display: none;
  }
`;

export const NavbarBox = styled(Box)`
  @media (max-width: 767px) {
    display: none;
  }
`;

export const CarouselBox = styled(Box)`
  padding: 15px 0;
  max-width: 450px;
  width: 100%;
  margin: 0 auto;

  .carousel-content {
    height: 100%;
    width: 100%;
    max-width: 450px;
  }

  .carousel-item {
    height: 600px;
    max-width: 100%;

    img {
      width: 100%;
      height: 350px;
    }

    .img-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .carousel-caption {
    position: absolute;
    bottom: 0;
    top: 53%;
    padding-top: 50px;
    padding-bottom: 3px;
    text-align: left;

    h5 {
      font-size: 18px;
      font-weight: bolder;
      line-height: 24px;
      width: fit-content;
      padding-bottom: 15px;
      color: black;
      font-family: "ProximaNova", sans-serif !important;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        bottom: 8px;
        width: 100%;
        height: 3px;
        background: #48b7a7;
        left: 0;
        border-radius: 30px;
      }
    }
  }

  .carousel-control-next-icon {
    width: 32px;
  }
  .carousel-control-prev {
    left: -50px;
    filter: invert(100%);
  }

  .carousel-control-next {
    filter: invert(100%);
    right: -50px;
  }
`;

export const LoginForm = styled("div")`
  padding: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  .titleLog {
    font-size: 27px;
    margin-top: 30px;
    font-weight: bolder;
    @media (max-width: 768px) {
      text-align: center;
    }
  }
  .title {
    margin-top: 10px;
    font-weight: bold;
    letter-spacing: 0.3px;
    font-size: 14px;
  }
  .form-field {
    width: 100%;
    margin-bottom: 1rem;
    @media (max-width: 768px) {
      width: 100%;
      margin-right: 0;
      max-width: 767px;
    }
  }
  .signinform {
    max-width: 310px;
    @media (max-width: 768px) {
      padding: 10px;
      width: 100%;
      max-width: 550px;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const OptionTextStyle = styled(Box)`
  font-size: 15px;
  margin-top: 30px;
  font-weight: bold;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const StyledLogoBox = styled(Box)`
  display: flex;
  margin: 30px auto 30px 10%;
`;

export const StyledImg = styled("img")`
  width: 125px;
  height: 45px;
`;

export const ForgotPasswordTextStyle = styled("div")`
  font-size: 15px;
  margin: 2px 0px 6px;
  font-weight: bold;
  @media (max-width: 768px) {
    text-align: center;
  }
`;
