import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

import { StyledTableHeader } from "./styles";

interface DataTableProps {
  foundData: any[];
  missingData: any[];
  showFound: boolean;
  showMissing: boolean;
}

const DataTable: React.FC<DataTableProps> = ({
  foundData,
  missingData,
  showFound,
  showMissing,
}) => {
  const [foundPage, setFoundPage] = useState(0);
  const [foundRowsPerPage, setFoundRowsPerPage] = useState(5);
  const [foundPaginatedData, setFoundPaginatedData] = useState<any[]>([]);
  const [missingPage, setMissingPage] = useState(0);
  const [missingRowsPerPage, setMissingRowsPerPage] = useState(5);
  const [missingPaginatedData, setMissingPaginatedData] = useState<any[]>([]);

  useEffect(() => {
    const startFoundIndex = foundPage * foundRowsPerPage;
    const endFoundIndex = startFoundIndex + foundRowsPerPage;
    setFoundPaginatedData(foundData.slice(startFoundIndex, endFoundIndex));
    const startMissingIndex = missingPage * missingRowsPerPage;
    const endMissingIndex = startMissingIndex + missingRowsPerPage;
    setMissingPaginatedData(
      missingData.slice(startMissingIndex, endMissingIndex)
    );
  }, [
    foundData,
    missingData,
    foundPage,
    foundRowsPerPage,
    missingPage,
    missingRowsPerPage,
  ]);

  const handleFoundChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setFoundPage(newPage);
  };

  const handleFoundChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFoundRowsPerPage(parseInt(event.target.value, 10));
    setFoundPage(0);
  };

  const handleMissingChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setMissingPage(newPage);
  };

  const handleMissingChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMissingRowsPerPage(parseInt(event.target.value, 10));
    setMissingPage(0);
  };

  return (
    <div>
      <TableContainer component={Paper} sx={{ width: "100%" }}>
        <Table sx={{ backgroundColor: "#EDEDED" }} aria-label="simple table">
          <TableHead>
            {showFound && (
              <TableRow>
                <StyledTableHeader align="center">UPC</StyledTableHeader>
                <StyledTableHeader align="center">Item Name</StyledTableHeader>
                <StyledTableHeader align="center">Quantity</StyledTableHeader>
                <StyledTableHeader align="center">Sell Price</StyledTableHeader>
                <StyledTableHeader align="center">
                  Sell By Date
                </StyledTableHeader>
                <StyledTableHeader align="center">
                  Location (ZIP)
                </StyledTableHeader>
              </TableRow>
            )}
            {showMissing && (
              <TableRow>
                <StyledTableHeader align="center">UPC</StyledTableHeader>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {showFound &&
              foundPaginatedData.map((item, index) => (
                <React.Fragment key={index}>
                  <TableRow key={item._id}>
                    <TableCell>{item.upc}</TableCell>
                    <TableCell>{item.itemName}</TableCell>
                    <TableCell>{item.quantity}</TableCell>
                    <TableCell>{item.sellPrice}</TableCell>
                    <TableCell>{item.sellDate}</TableCell>
                    <TableCell>{item.location}</TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            {showMissing &&
              missingPaginatedData.map((upc, index) => (
                <TableRow key={index}>
                  <TableCell>{upc}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {showFound && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={foundData.length}
          rowsPerPage={foundRowsPerPage}
          page={foundPage}
          onPageChange={handleFoundChangePage}
          onRowsPerPageChange={handleFoundChangeRowsPerPage}
          sx={{
            "& .MuiTablePagination-displayedRows": {
              marginBottom: "0rem",
            },
            "& .MuiTablePagination-selectLabel": {
              marginBottom: "0rem",
            },
          }}
        />
      )}
      {showMissing && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={missingData.length}
          rowsPerPage={missingRowsPerPage}
          page={missingPage}
          onPageChange={handleMissingChangePage}
          onRowsPerPageChange={handleMissingChangeRowsPerPage}
          sx={{
            "& .MuiTablePagination-displayedRows": {
              marginBottom: "0rem",
            },
            "& .MuiTablePagination-selectLabel": {
              marginBottom: "0rem",
            },
          }}
        />
      )}
    </div>
  );
};

export default DataTable;
