import React, { useState, useEffect } from "react";
import { Alert, InputLabel, Select, SelectChangeEvent } from "@mui/material";
import axios from "axios";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";

import { FileUploadButton, FileUploadHeader, AlertBox } from "./styles";

import "../../assets/styling/style.css";

// const bucketName = "sotira-excess-files-test";
// const region = "us-west-1";
// const accessKeyId = "AKIA5NU6SDDXI5HUA7PT";
// const secretAccessKey = "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";

export default function WPopup() {
  const storageTypes = [
    "WMS",
    "TMS",
    "Storage Capacity Model",
    "Google Sheets",
  ];
  const [storage, setStorage] = useState("Google Sheets");

  // const [authUrl, setAuthUrl] = useState<string | null>(null);
  // const [isAuthenticated, setIsAuthenticated] = useState(false);

  const date = new Date();

  //Get Month/Year for updated Calendy Link
  const year = String(date.getFullYear());
  var month = String(date.getMonth() + 1);
  if (date.getMonth() + 1 < 10) {
    month = "0" + String(month);
  }

  // const s3 = new aws.S3({
  //   region,
  //   accessKeyId,
  //   secretAccessKey,
  // });

  const handleAuth = async () => {
    const authToken = localStorage.getItem("gsheetToken");

    if (authToken) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/verify_token`,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );

        if (response.data.valid) {
          window.location.href = "/sheet-selection";
          return;
        }
      } catch (error) {
        console.log("Token expired or invalid, redirecting to sign-in");
        getAuthUrl();
      }
    }
  };

  const getAuthUrl = async () => {
    try {
      var url = "";
      await axios
        .get(process.env.REACT_APP_API_BASE_URL + "sheets/auth/google")
        .then((response) => {
          console.log(response);
          // setAuthUrl(response.data.url);
          url = response.data.url;
        });

      window.location.href = url;
    } catch (error) {
      console.error("Error fetching auth URL:", error);
    }
  };

  const handleAuthComplete = async (code: string) => {
    try {
      var params = {
        code: code,
      };
      await axios
        .post(process.env.REACT_APP_API_BASE_URL + "sheets/auth_code", params)
        .then((response) => {
          console.log(response);
          if (response.data.token) {
            localStorage.setItem("googleToken", response.data.token);
            // setIsAuthenticated(true);
          }
        });
    } catch (error) {
      console.error("Error completing authentication:", error);
    }
  };

  // const fetchSheets = async () => {
  //   const token = localStorage.getItem("googleToken");
  //   if (!token) {
  //     console.error("No auth token found");
  //     return;
  //   }

  //   try {
  //     var params = {
  //       spreadsheetId: spreadsheetId,
  //       auth: token,
  //     };
  //     await axios
  //       .post(process.env.REACT_APP_API_BASE_URL + "sheets/spreadsheet", params)
  //       .then((response) => {
  //         console.log(response);
  //         setSheets(response.data.sheets || []);
  //       });
  //   } catch (error) {
  //     console.error("Error fetching sheets:", error);
  //   }
  // };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    if (code) {
      handleAuthComplete(code);
      // Clear the code from the URL
      window.history.replaceState({}, document.title, "/");
    }
  }, []);

  const handleChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    var type: any = value;
    setStorage(type);
  };

  return (
    <>
      <div>
        {" "}
        <FileUploadHeader>Connect Storage System</FileUploadHeader>
        <div style={{ border: "1px solid #CCC", marginBottom: "20px" }}></div>
        <InputLabel id="system-lable">Select System Type</InputLabel>
        <Select
          labelId="system-label"
          id="system"
          value={storage}
          onChange={handleChange}
          input={<OutlinedInput label="Storage System" />}
        >
          {storageTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
        <br />
        <AlertBox>
          <Alert severity="error" className="alert-text">
            Trouble connecting your inventory data? Email{" "}
            <a href="mailto:support@sotira.co">support@sotira.co</a> or{" "}
            <a
              href={
                "https://calendly.com/amritabhasin/15min?back=1&month=" +
                year +
                "-" +
                month
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              schedule a meeting at this link
            </a>{" "}
            and we will connect it for you.
          </Alert>
        </AlertBox>
      </div>

      {storage !== "Google Sheets" && (
        <div>
          <FileUploadButton>
            Contact us for custom integrations
          </FileUploadButton>

          <br />
        </div>
      )}

      {storage === "Google Sheets" && (
        <FileUploadButton onClick={handleAuth}>
          Connect Google Drive
        </FileUploadButton>
      )}
    </>
  );
}
