import React, { useRef, useState, useEffect } from "react";
import aws from "aws-sdk";
import axios from "axios";
import { toast } from "react-toastify";
import { Alert } from "@mui/material";
import Form from "react-bootstrap/Form";

import {
  FileUploadButton,
  FileUploadHeader,
  FileUploadBox,
  AlertBox,
  UploadedFile,
} from "./styles";
import "../../assets/styling/style.css";

const bucketName = "sotira-excess-files-test";
const region = "us-west-1";
const accessKeyId = "AKIA5NU6SDDXI5HUA7PT";
const secretAccessKey = "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";

export default function UPopup() {
  const queryParameters = new URLSearchParams(window.location.search);
  const code = queryParameters.get("c");

  const [file, setFile] = useState<File | null>(null);
  const [upload, setUpload] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [location, setLocation] = useState("");
  const [priority, setPriority] = useState("");
  const [fileExtension, setFileExtension] = useState(false);
  const [confirm, setConfirm] = useState(false);

  //email List Items

  const date = new Date();

  //Get Month/Year for updated Calendy Link
  const year = String(date.getFullYear());
  var month = String(date.getMonth() + 1);
  if (date.getMonth() + 1 < 10) {
    month = "0" + String(month);
  }

  useEffect(() => {
    setUpload(email.length > 0 && phone.length > 0 && location.length > 0 && priority.length > 0 && !!file && fileExtension);
  }, [email.length, phone.length, location.length, priority.length, file, fileExtension]);

  const handleFileChangeRaw = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const uploadedFile = e.target.files[0];
      const fileExtension = uploadedFile.name.split(".").pop()?.toLowerCase();
      if (fileExtension === "csv") {
        setFile(uploadedFile);
        setFileExtension(true);
      } else if (fileExtension === "xlsx" || fileExtension === "xls") {
        setFile(uploadedFile);
        setFileExtension(true);
      } else {
        setFileExtension(false);
        toast.error("Unsupported file format.", {
          position: "top-right",
          autoClose: 1500,
        });
      }
    }
  };

  const handleFileinput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const s3 = new aws.S3({
    region,
    accessKeyId,
    secretAccessKey,
  });

  const handleUpload = async () => {
    if (!file) {
      console.error("No file selected.");
      return;
    }

    const fileKey = `publicUpload/${email}/${date}/${file.name}`;

    const params = {
      Key: fileKey,
      Bucket: bucketName,
      Body: file,
      ContentType: file.type,
    };

    var params_listing = {
      name: name,
      email: email,
      phone: phone,
      location: location,
      priority: priority,
      fileId: fileKey,
      code: code,
      logged: false,
    };

    try {
      await s3.putObject(params).promise();
    } catch (error: any) {
      console.error("Error uploading file:", error);
    }

    try {
      await axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "offer/full_upload",
          params_listing
        )
        .then((response) => {
          toast.success("Inventory Uploaded", {
            position: "top-right",
            autoClose: 1500,
          });
          setConfirm(true);
        });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.error("Error Submitting Order:", error);
    }
  };

  return (
    <>
      <Form onSubmit={(e) => e.preventDefault()}>
      {confirm ? <div> 

        <FileUploadHeader>Confirm Upload</FileUploadHeader>
        <AlertBox>
          <Alert severity="success" className="alert-text">
          Thanks for uploading! We will be in touch ASAP. In the meantime, please reach out to {" "}
          <a href="mailto:support@sotira.co">support@sotira.co</a> with any questions.
          </Alert>
        </AlertBox>


        </div> : <div>
        <div>
          {" "}
          <FileUploadHeader>Upload Data</FileUploadHeader>
          <div style={{ border: "1px solid #CCC", marginBottom: "20px" }}></div>
          <div> Upload data sheet here in .csv or .xlsx format </div>
          <br />
          <Form.Group className="form-field">
          <Form.Label className="title">Email *</Form.Label>
            <Form.Control
              autoFocus
              type="email"
              placeholder="Enter email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <br/>
          <Form.Group className="form-field">
          <Form.Label className="title">Name *</Form.Label>
            <Form.Control
              autoFocus
              type="text"
              placeholder="Enter your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <br/>
          <Form.Group className="form-field">
          <Form.Label className="title">Phone Number *</Form.Label>
            <Form.Control
              autoFocus
              type="text"
              placeholder="Phone Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Form.Group>
          <br/>
          <Form.Group className="form-field">
          <Form.Label className="title">Location *</Form.Label>
            <Form.Control
              autoFocus
              type="text"
              placeholder="City, State"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </Form.Group>
          <br/>
          <Form.Group className="form-field">
          <Form.Label className="title">When do you need to this cleared by? *</Form.Label>
            <Form.Control
              autoFocus
              type="text"
              placeholder="3 weeks"
              value={priority}
              onChange={(e) => setPriority(e.target.value)}
            />
          </Form.Group>
          <br />
          <FileUploadBox onClick={handleFileinput}>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChangeRaw}
              disabled={file !== null}
            />
            {!file ? (
              <p style={{ padding: "20px", marginBottom: "0px" }}>
                Drop file here or click to upload
              </p>
            ) : (
              <>
                <UploadedFile>{file.name}</UploadedFile>
              </>
            )}
          </FileUploadBox>
          <AlertBox>
            <Alert severity="error" className="alert-text">
              Trouble uploading your inventory data? Email your file to{" "}
              <a href="mailto:support@sotira.co">support@sotira.co</a> or{" "}
              <a
                href={
                  "https://calendly.com/amritabhasin/15min?back=1&month=" +
                  year +
                  "-" +
                  month
                }
                target="_blank"
                rel="noreferrer"
              >
                schedule a meeting at this link
              </a>{" "}
              and we will upload it for you.
            </Alert>
          </AlertBox>
        </div>

        <div>
          <FileUploadButton onClick={handleUpload} disabled={!upload}>
            Upload Inventory Now
          </FileUploadButton>
        </div>
        </div> }
      </Form>
    </>
  );
}
