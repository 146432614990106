import { jwtDecode } from "jwt-decode";

const getUser = () => {
  const token = localStorage.getItem("token");
  if (token) {
    let user: any;
    user = jwtDecode(token);
    return String(user.id);
  } else {
    return "none";
  }
};
export default getUser;
