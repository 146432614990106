import BuyerSearchResults from "../buyerResults";

var fData: any[] = [];
var mData: any[] = [];

const BuyerSearchResultsWrapper = () => {
  const updateFoundData = (data: any[]) => {
    fData = data;
  };

  const updateMissingData = (data: any[]) => {
    mData = data;
  };

  return (
    <BuyerSearchResults
      foundData={fData}
      missingData={mData}
      updateFoundData={updateFoundData}
      updateMissingData={updateMissingData}
    />
  );
};
export default BuyerSearchResultsWrapper;
