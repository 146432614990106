import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "./DataTable";

import { buyerDataUploadRoute } from "../../Routes/constants";

import {
  DataPreviewBox,
  DataPreviewText,
  PreviewBox,
  ContentText,
  Datatable,
  NextButton,
  ButtonContainer,
} from "./styles";

interface BuyerSearchProps {
  foundData: any[]; // Define the type for foundData
  missingData: any[]; // Define the type for missingData
  updateFoundData: (data: any[]) => void; // Define the type for updateFoundData
  updateMissingData: (data: any[]) => void; // Define the type for updateMissingData
}

const BuyerSearchResults: React.FC<BuyerSearchProps> = ({
  foundData,
  missingData,
}) => {
  const [showFound, setShowFound] = useState(true);
  const [showMissing, setShowMissing] = useState(false);
  const navigate = useNavigate();

  const handlestorageCost = () => {
    navigate(buyerDataUploadRoute);
  };

  const handleShowMissing = () => {
    setShowFound(false);
    setShowMissing(true);
  };

  const handleShowFound = () => {
    setShowFound(true);
    setShowMissing(false);
  };

  return (
    <>
      <DataPreviewBox>
        <PreviewBox>
          {showFound && (
            <div>
              <DataPreviewText>Matched Items</DataPreviewText>
              {foundData.length > 0 ? (
                <ContentText>
                  Success! We've matched you with available inventory.
                </ContentText>
              ) : (
                <ContentText>No matches were found.</ContentText>
              )}
            </div>
          )}
          {showMissing && <DataPreviewText>Missing UPC codes</DataPreviewText>}
        </PreviewBox>
        <Datatable>
          <DataTable
            foundData={foundData}
            missingData={missingData}
            showFound={showFound}
            showMissing={showMissing}
          />
          <ButtonContainer>
            <NextButton onClick={handlestorageCost}>New Search</NextButton>
            {showFound && (
              <NextButton onClick={handleShowMissing}>Show Missing</NextButton>
            )}
            {showMissing && (
              <NextButton onClick={handleShowFound}>Show Found</NextButton>
            )}
          </ButtonContainer>
        </Datatable>
      </DataPreviewBox>
    </>
  );
};

export default BuyerSearchResults;
