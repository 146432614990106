import * as React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useMediaQuery,
  Box,
  Drawer,
  Toolbar,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { logoutUserSuccess } from "../../Redux/login/reducer";
import { authUser } from "../../Redux/auth/reducer";

import { homeRoute, logoutRoute } from "../../Routes/constants";
import { setting, sideBarList } from "../../constant";

import { AppBar, AvatarImg, ProfileImage, DrawerHeader, Main } from "./styles";

import sotiraLogo from "../../assets/images/logo.png";

const drawerWidth = 300;
interface AuthLayoutProp {
  children: any;
}

const AuthLayout: React.FC<AuthLayoutProp> = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const [open, setOpen] = React.useState(false);

  const firstName = localStorage.getItem("FirstName");
  const lastName = localStorage.getItem("LastName");

  const handleDrawerChange = () => {
    setOpen(!open);
  };

  const handleClick = (url: any) => {
    if (url === logoutRoute) {
      dispatch(logoutUserSuccess());
      dispatch(authUser({ isLoggedIn: false }));
      navigate(homeRoute);
    } else {
      navigate(url);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        open={open}
        sx={{ backgroundColor: "#FFF", boxShadow: "none" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: open ? "end" : "space-between",
          }}
        >
          {!open && (
            <Toolbar>
              <IconButton
                aria-label="open drawer"
                onClick={handleDrawerChange}
                edge="start"
                sx={[
                  {
                    mr: 2,
                  },
                ]}
              >
                <MenuIcon />
              </IconButton>
              <img
                alt="logo"
                src={sotiraLogo}
                className="sotira-logo"
                onClick={() => navigate(homeRoute)}
              />
            </Toolbar>
          )}
          <Box
            sx={{
              padding: "5px 24px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ProfileImage>
              <span className="profile-text">
                {firstName} {lastName}
              </span>
              {""}
              <AvatarImg>
                {firstName?.[0]}
                {lastName?.[0]}
              </AvatarImg>
            </ProfileImage>
          </Box>
        </Box>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant={isSmallScreen ? "temporary" : "persistent"}
        anchor="left"
        open={open}
      >
        <DrawerHeader sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Toolbar>
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerChange}
              edge="start"
              sx={[
                {
                  mr: 2,
                },
              ]}
            >
              <MenuIcon />
            </IconButton>
            <img
              alt="logo"
              src={sotiraLogo}
              className="sotira-logo"
              onClick={() => navigate(homeRoute)}
            />
          </Toolbar>
        </DrawerHeader>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box>
            <List>
              {sideBarList.map(({ name, icon, url }, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton onClick={() => navigate(url)}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText
                      primary={name}
                      sx={{
                        "& span": {
                          fontSize: "20px",
                          fontWeight: 400,
                          fontFamily: "ProximaNova !important",
                          color: "#141414",
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
          <Box>
            <List>
              {setting.map(({ name, icon, url }, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton onClick={() => handleClick(url)}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText
                      primary={name}
                      sx={{
                        "& span": {
                          fontSize: "20px",
                          fontWeight: 400,
                          fontFamily: "ProximaNova !important",
                          color: "#141414",
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
        <Divider />
      </Drawer>
      <Main open={open} isSmallScreen={isSmallScreen}>
        <DrawerHeader />
        {children}
      </Main>
    </Box>
  );
};

export default AuthLayout;
