import { List, ListItem } from "@mui/material";
import { NavBarItemText } from "./styles";

const navItems = [
  { text: "About", href: "https://www.sotira.co/about" },
  { text: "Blog", href: "https://www.sotira.co/blog" },
  { text: "Contact", href: "https://www.sotira.co/contact" },
  { text: "Sign Up", href: "/auth/register" },
];

const NavBar = () => {
  return (
    <List sx={{ display: "flex" }}>
      {navItems.map(({ text, href }) => (
        <ListItem key={text} component="a" href={href} sx={{ padding: "0px" }}>
          <NavBarItemText primary={text} isSignUp={text === "Sign Up"} />
        </ListItem>
      ))}
    </List>
  );
};

export default NavBar;
