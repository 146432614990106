import { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Form from "react-bootstrap/Form";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import Carousel from "react-bootstrap/Carousel";
import Grid from "@mui/material/Grid2";
import { Box, CircularProgress } from "@mui/material";

import { loginSuccess } from "../../Redux/login/reducer";
import {
  useLazygetUserNameQuery,
  usePostLoginMutation,
  usePostGoogleLoginMutation,
  useLazyGetBusinessinfo,
} from "../../Redux/api/User";
import { authUser } from "../../Redux/auth/reducer";

import NavBar from "../Navbar";

import {
  BusinessinfoRoute,
  RegisterRoute,
  forgotpasswordRoutes,
} from "../../Routes/constants";

import getUser from "../../Utils/getUser";
import { generateHash } from "../../Utils/generatehash";

import larlogo from "../../assets/images/signup_graphics/logo-beta.png";
import img1 from "../../assets/images/signup_graphics/financial _tracking.png";
import img2 from "../../assets/images/signup_graphics/expensetracker.png";
import img3 from "../../assets/images/signup_graphics/pricingdiscovery.png";
import img4 from "../../assets/images/signup_graphics/analyticsandoptimization.png";

import "bootstrap/dist/css/bootstrap.min.css";
import {
  ForgotPasswordTextStyle,
  OptionTextStyle,
  StyledImg,
  StyledLogoBox,
  CarouselBox,
  RightPanel,
  LoginForm,
  LeftPanel,
  NavbarBox,
} from "../register/styles";
import { StyleButton } from "../../Common/styles";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [login, { isLoading: isLogin }]: any = usePostLoginMutation();
  const [googlelogin]: any = usePostGoogleLoginMutation();
  const [getuserName]: any = useLazygetUserNameQuery();
  const [getBusinessInfo]: any = useLazyGetBusinessinfo();

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Please enter valid email Id")
      .required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const { email, password } = values;

      try {
        const {
          data: { token },
        } = await login({ email, password });
        localStorage.setItem("token", token);
        dispatch(loginSuccess({ email: values.email }));

        const { data: businessinfo } = await getBusinessInfo(getUser());

        if (businessinfo?.filled) {
          const { data: userinfo } = await getuserName(getUser());
          localStorage.setItem("FirstName", userinfo?.firstName);
          localStorage.setItem("LastName", userinfo?.lastName);
          dispatch(authUser({ isLoggedIn: true }));
        } else {
          navigate(BusinessinfoRoute, { replace: true });
        }
      } catch (err) {}
    },
  });

  const { values, handleSubmit, handleChange, handleBlur, errors, touched } =
    formik;

  const responseGoogle = async (response: any) => {
    const creds = response.credential;

    if (creds) {
      try {
        const userInfo: any = jwtDecode(creds);
        const payload: any = {
          email: userInfo.email,
          googleId: userInfo.sub,
          firstName: userInfo.given_name,
          lastName: userInfo.family_name,
          log_token: generateHash(userInfo),
        };
        const {
          data: { token },
        } = await googlelogin(payload);
        localStorage.setItem("token", token);
        dispatch(loginSuccess({ email: values.email }));

        const { data: businessinfo } = await getBusinessInfo(getUser());

        if (businessinfo?.filled) {
          const { data: userinfo } = await getuserName(getUser());
          localStorage.setItem("FirstName", userinfo?.firstName);
          localStorage.setItem("LastName", userinfo?.lastName);
          dispatch(authUser({ isLoggedIn: true }));
        } else {
          navigate(BusinessinfoRoute, { replace: true });
        }
      } catch (err) {}
    }
  };

  return (
    <>
      <div>
        <Grid container spacing={0} sx={{ height: "100vh" }}>
          <Grid size={{ xs: 0, sm: 0, md: 7, lg: 7 }}>
            <LeftPanel>
              <StyledLogoBox>
                <a href="https://www.sotira.co/">
                  <StyledImg alt="logo" src={larlogo} />
                </a>
              </StyledLogoBox>
              <Box>
                <CarouselBox>
                  <Carousel
                    pause="hover"
                    className="carousel-content "
                    bsPrefix="carousel"
                    indicators={true}
                    fade={true}
                    nextLabel={null}
                    prevLabel={null}
                    slide={false}
                    activeIndex={index}
                    onSelect={handleSelect}
                  >
                    <Carousel.Item>
                      <div className="img-wrapper">
                        <img src={img1} alt="First slide" />
                      </div>
                      <Carousel.Caption>
                        <h5>
                          Discover how much cash you can unlock for your excess
                          inventory
                        </h5>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <Box className="img-wrapper">
                        <img src={img2} alt="Second slide" />
                      </Box>
                      <Carousel.Caption>
                        <h5>Connect with a vetted network of private buyers</h5>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <Box className="img-wrapper">
                        <img src={img3} alt="Third slide" />
                      </Box>
                      <Carousel.Caption>
                        <h5>
                          Convert dead inventory to working capital within a
                          week
                        </h5>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="img-wrapper">
                        <img src={img4} alt="Fourth slide" />
                      </div>
                      <Carousel.Caption>
                        <h5>
                          Assess and compare profitable and efficient offloading
                          options
                        </h5>
                      </Carousel.Caption>
                    </Carousel.Item>
                  </Carousel>
                </CarouselBox>
              </Box>
            </LeftPanel>
          </Grid>
          <Grid size={{ xs: 12, sm: 12, md: 5, lg: 5 }}>
            {/* this is the side Login */}

            <RightPanel>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "20px",
                }}
              >
                <StyledImg alt="logo" src={larlogo} />
              </Box>
              <LoginForm>
                <NavbarBox>
                  <NavBar />
                </NavbarBox>
                <h2 className="titleLog"> Log In </h2>
                <Form className="signinform" onSubmit={handleSubmit}>
                  <Form.Group className="form-field">
                    <GoogleLogin
                      size="large"
                      width="170"
                      onSuccess={responseGoogle}
                      onError={() => {
                        console.log("Login Failed");
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="form-field">
                    <Form.Label className="title"> Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Enter email address"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.email && !!errors.email}
                    />
                    {touched.email && errors.email && (
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group className="form-field">
                    <Form.Label className="title">Password</Form.Label>
                    <Form.Control
                      type="text"
                      name="password"
                      value={values.password}
                      placeholder="Enter password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.password && !!errors.password}
                    />
                    {touched.password && errors.password && (
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <StyleButton
                    type="submit"
                    disabled={isLogin}
                    endIcon={
                      isLogin ? (
                        <CircularProgress size={18} sx={{ color: "#48b7a7" }} />
                      ) : null
                    }
                  >
                    Login
                  </StyleButton>
                </Form>
                <OptionTextStyle>
                  Don't have an account?
                  <a
                    style={{ color: "#89D0C6", textDecoration: "none" }}
                    href={RegisterRoute}
                  >
                    Register here
                  </a>
                </OptionTextStyle>
                <ForgotPasswordTextStyle>
                  Forgot password?
                  <a
                    style={{ color: "#89D0C6", textDecoration: "none" }}
                    href={forgotpasswordRoutes}
                  >
                    Click Here
                  </a>
                </ForgotPasswordTextStyle>
              </LoginForm>
            </RightPanel>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Login;
