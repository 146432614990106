import { Avatar, Box, styled } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";

const drawerWidth = 300;
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "isSmallScreen",
})<{
  open?: boolean;
  isSmallScreen?: boolean;
}>(({ theme, open, isSmallScreen }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: isSmallScreen ? 0 : `-${drawerWidth}px`,
  variants: [
    {
      props: { open },
      style: {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: open || isSmallScreen ? 0 : `-${drawerWidth}px`,
      },
    },
  ],
}));

export const AvatarImg = styled(Avatar)`
  background-color: #41c175;
  width: 60px;
  height: 60px;

  @media (max-width: 768px) {
    width: 34px;
    height: 34px;
  }
`;

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(["margin", "width"], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

export const ProfileImage = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  .profile-text {
    color: #343434;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0.66px;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
`;
export const DrawerHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
