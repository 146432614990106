import { ConfirmationText } from "./styles";

export default function CPopup() {
  return (
    <>
      <ConfirmationText> Offer Sent! </ConfirmationText>
      <br />
      <ConfirmationText>
        {" "}
        Once the supplier approves, you will receive an email with next steps.
      </ConfirmationText>
    </>
  );
}
