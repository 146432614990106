import { useState, useMemo } from "react";
import dayjs, { Dayjs } from "dayjs";
import axios from "axios";
import { toast } from "react-toastify";
import { DialogContentText } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { NextButton } from "./styles";

interface Location {
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  schedule: any;
}

interface PPopupProps {
  location?: Location;
  offerDates: any[];
  offerID: string;
}

export default function PPopup({ location, offerDates, offerID }: PPopupProps) {
  const [selectedDateTime, setSelectedDateTime] = useState<dayjs.Dayjs | null>(
    null
  );
  const [error, setError] = useState<string>("");
  const [isTimeSelected, setIsTimeSelected] = useState<boolean>(false);
  const latestOfferDate = dayjs(offerDates[offerDates.length - 1]);
  console.log(latestOfferDate);
  const maxDate = latestOfferDate.add(7, "day");
  console.log(maxDate);
  console.log(latestOfferDate);
  console.log(maxDate);

  const errorMessage = useMemo(() => {
    switch (error) {
      case "maxTime": {
        return "Invalid Date - See Above";
      }
      case "minTime": {
        return "Invalid Date - See Above";
      }

      default: {
        return "";
      }
    }
  }, [error]);

  const handleDateTimeChange = (newValue: dayjs.Dayjs | null) => {
    if (newValue) {
      const minTime = getMinTime();
      const maxTime = getMaxTime();
      const isValidTime =
        newValue.isSame(minTime) ||
        newValue.isSame(maxTime) ||
        (newValue.isAfter(minTime) && newValue.isBefore(maxTime));
      setIsTimeSelected(isValidTime);
    } else {
      setIsTimeSelected(false);
      setError("");
    }
    setSelectedDateTime(newValue);
  };

  const availableDays =
    location?.schedule?.map((schedule: any) => schedule.day) || [];

  const handleSelectTime = async (time: Dayjs | null) => {
    if (time) {
      console.log("Selected Time:", time.format("hh:mm A"));
    }
    const params = {
      offerID: offerID,
      pickuptime: time,
    };
    await axios
      .post(process.env.REACT_APP_API_BASE_URL + "offer/set_pickup", params)
      .then((response) => {
        toast.success("Pickup time has been scheduled", {
          position: "top-right",
          autoClose: 1500,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
  };

  const isPickupDay = (date: dayjs.Dayjs) => {
    const day = date.format("dddd");
    return availableDays.includes(day);
  };

  const matchedDay = useMemo(() => {
    if (!selectedDateTime) return undefined;

    // Get the day of the week from the selected date
    const dayOfWeek = selectedDateTime.format("dddd"); // Returns 'Monday', 'Tuesday', etc.

    // Find the corresponding start and end times for the day
    return location?.schedule?.find((day: any) => day.day === dayOfWeek);
  }, [selectedDateTime, location?.schedule]);

  const getMinTime = () => {
    if (!matchedDay) return undefined;

    // Get the hour and minute from the start time, apply it to the selected date
    const start = dayjs(matchedDay.start);
    return selectedDateTime
      ?.set("hour", start.hour())
      .set("minute", start.minute())
      .set("second", 0);
  };

  const getMaxTime = () => {
    if (!matchedDay) return undefined;
    console.log(matchedDay);
    // Get the hour and minute from the end time, apply it to the selected date
    const end = dayjs(matchedDay.end);
    console.log(end);
    return selectedDateTime
      ?.set("hour", end.hour())
      .set("minute", end.minute())
      .set("second", 0);
  };

  return (
    <div>
      {location && location.schedule && location.schedule.length > 0 ? (
        <div>
          <div>Available Pickup Days</div>
          <p>{availableDays.join(", ")}</p>

          <div>
            <DialogContentText>
              <strong>Choose a time to pickup:</strong>
              <br />
            </DialogContentText>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label="Select date and time"
                value={selectedDateTime}
                onChange={handleDateTimeChange}
                minDate={latestOfferDate}
                maxDate={maxDate}
                minTime={matchedDay ? getMinTime() : undefined} // Set minimum time based on matched day
                maxTime={matchedDay ? getMaxTime() : undefined}
                ampm
                shouldDisableDate={(date: dayjs.Dayjs) => !isPickupDay(date)}
                slotProps={{
                  textField: {
                    helperText: errorMessage,
                  },
                  popper: {
                    placement: "right",
                  },
                }}
                sx={{ marginTop: "20px" }}
              />
            </LocalizationProvider>
          </div>

          {isTimeSelected && (
            <NextButton onClick={() => handleSelectTime(selectedDateTime)}>
              Select Pickup Time
            </NextButton>
          )}
        </div>
      ) : (
        <DialogContentText>
          No pickup schedule available for this location right now.
        </DialogContentText>
      )}
    </div>
  );
}
