import React, { useRef, useState } from "react";
import aws from "aws-sdk";
import { Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { confirmationRoutes } from "../../Routes/constants";

import { usePostAddExcessFile, useGetExcessId } from "../../Redux/api/User";

import getUser from "../../Utils/getUser";

import {
  FileUploadButton,
  FileUploadHeader,
  FileUploadBox,
  AlertBox,
  UploadedFile,
} from "./styles";

import "../../assets/styling/style.css";

const bucketName = "sotira-excess-files-test";
const region = "us-west-1";
const accessKeyId = "AKIA5NU6SDDXI5HUA7PT";
const secretAccessKey = "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";

export default function UPopup() {
  const navigate = useNavigate();
  const userID = getUser();
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [AddExcessFile]: any = usePostAddExcessFile();
  const { data: { data: { excess = [] } = {} } = {} } = useGetExcessId(userID);

  const latestDateObject = excess.find((excessdata: any) => {
    return !excessdata?.fileName;
  });

  const s3 = new aws.S3({
    region,
    accessKeyId,
    secretAccessKey,
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleFileinput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleUpload = async () => {
    if (!file) {
      console.error("No file selected.");
      return;
    }
    const fileKey = `${userID}/${file.name}`;

    const params = {
      Key: fileKey,
      Bucket: bucketName,
      Body: file,
      ContentType: file.type,
    };

    try {
      await s3.putObject(params).promise();
      const payload = {
        userID,
        excessID: latestDateObject?._id,
        fileName: file.name,
      };

      await AddExcessFile(payload);
      navigate(confirmationRoutes);
    } catch (error: any) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <>
      <div>
        {" "}
        <FileUploadHeader>Upload Data</FileUploadHeader>
        <div style={{ border: "1px solid #CCC", marginBottom: "20px" }}></div>
        <FileUploadBox onClick={handleFileinput}>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
            disabled={file !== null}
          />
          {!file ? (
            <p style={{ padding: "20px", marginBottom: "0px" }}>
              Drop file here or click to upload
            </p>
          ) : (
            <>
              <UploadedFile>{file.name}</UploadedFile>
            </>
          )}
        </FileUploadBox>
        <AlertBox>
          <Alert severity="error" className="alert-text">
            Trouble uploading your inventory data? Email your file to{" "}
            <a href="mailto:support@sotira.co">support@sotira.co</a> or{" "}
            <a href="https://calendly.com/amritabhasin/15min?back=1&month=2024-01">
              schedule a meeting at this link
            </a>{" "}
            and we will upload it for you.
          </Alert>
        </AlertBox>
      </div>

      {file && (
        <FileUploadButton onClick={handleUpload}>
          Upload a file
        </FileUploadButton>
      )}
    </>
  );
}
