import React from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import AuthLayout from "../layout/AuthLayout";
import PubLayout from "../layout/PubLayout";

//routes
import {
  authProtectedRoutes,
  publicRoutes,
  accessibleRoutes,
} from "./allRoutes";

interface authUser {
  authUser: {
    isLoggedIn: boolean;
  };
}

const Index = () => {
  const token = localStorage.getItem("token");
  const { isLoggedIn } = useSelector((state: authUser) => state.authUser);

  return (
    <React.Fragment>
      <Routes>
        {!!token &&
          isLoggedIn &&
          authProtectedRoutes.map((route, idx) => {
            return (
              <Route
                path={route.path}
                element={<AuthLayout> {route.component}</AuthLayout>}
                key={idx}
              />
            );
          })}
        {!token &&
          publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                route.path === "/listing" ||
                route.path === "/offer" ||
                route.path === "/get-invoice" ? (
                  <PubLayout>{route.component}</PubLayout>
                ) : (
                  route.component
                )
              }
              key={idx}
            />
          ))}
        {accessibleRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<PubLayout>{route.component}</PubLayout>}
            key={idx}
          />
        ))}
      </Routes>
    </React.Fragment>
  );
};

export default Index;
