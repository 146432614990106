import styled from "@emotion/styled";
import { AppBar, Avatar } from "@mui/material";

interface CustomAppBarProps {
  isOpen: boolean;
}

export const CustomAppBar = styled(AppBar, {
  shouldForwardProp: (prop: string) => prop !== "isOpen",
})<CustomAppBarProps>(({ isOpen }) => ({
  color: "black",
  background: "transparent",
  ...(isOpen && {
    width: `calc(100% - 250px)`,
    marginLeft: "250px",
  }),
  boxShadow: "none",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "row",
  paddingLeft: "35px",
  paddingTop: "29px",
  paddingRight: "75px",
  backgroundColor: "white",
  "@media (max-width: 768px)": {
    paddingLeft: "20px",
    paddingRight: "20px",
    ".sotira-logo": {
      maxWidth: "80px",
      width: "100%",
      ...(isOpen && {
        maxWidth: "80px",
        width: "100%",
      }),
    },
  },
}));

export const DrawerHeader = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-start",
});

export const AvatarImg = styled(Avatar)`
  background-color: #41c175;
  width: 60px;
  height: 60px;

  @media (max-width: 768px) {
    width: 34px;
    height: 34px;
  }
`;

export const CustomMain = styled("main", {
  shouldForwardProp: (prop: any) => prop !== "isOpen",
})(({ isOpen }: { isOpen: boolean }) => ({
  width: "100%",
  padding: "0 10%",
  ...(isOpen && {
    width: `calc(100% - 250px)`,
    marginLeft: "250px",
  }),
  "@media(max-width:320px)": {
    ...(isOpen && {
      marginLeft: "245px",
    }),
  },
}));

export const ProfileImage = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  .profile-text {
    color: #343434;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: 0.66px;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
`;
