import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { DialogContentText, Stack, Rating, TextField } from "@mui/material";

import {
  ConfirmationHeader,
  NextButton,
  ConfirmBox,
  ConfirmSection,
} from "./styles";

interface Location {
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  schedule: any;
}

interface PPopupProps {
  location?: Location;
  offerDates: any[];
  offerID: string;
}

export default function PPopup({ location, offerDates, offerID }: PPopupProps) {
  // const availableDays =
  //   location?.schedule?.map((schedule: any) => schedule.day) || [];
  const [note, setNote] = useState("");
  const [rating, setRating] = useState<number | null>(null);

  const handleConfirmTime = async () => {
    const params = {
      offerID: offerID,
      rating: rating,
      note: note,
    };
    await axios
      .post(process.env.REACT_APP_API_BASE_URL + "offer/confirm_pickup", params)
      .then((response) => {
        toast.success("Pickup has been confirmed", {
          position: "top-right",
          autoClose: 1500,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
  };

  const handleRating = (e: any) => {
    setRating(Number(e.target.value));
  };

  const handleNote = (e: any) => {
    setNote(e.target.value);
  };

  return (
    <div>
      <ConfirmationHeader>Pickup Confirmation</ConfirmationHeader>

      <div style={{ border: "1px solid #CCC", marginBottom: "20px" }}></div>
      {location && location.schedule && location.schedule.length > 0 ? (
        <ConfirmBox>
          <ConfirmSection>
            <NextButton onClick={() => handleConfirmTime()}>
              Confirm Pickup
            </NextButton>
          </ConfirmSection>
          <ConfirmSection>
            <div> Optional Rating </div>
            <Stack spacing={1}>
              <Rating
                name="half-rating"
                value={rating}
                precision={0.5}
                onChange={handleRating}
              />
            </Stack>
            <div> Optional Notes </div>
            <br />
            <TextField
              id="outlined-multiline-static"
              label="Notes"
              multiline
              rows={4}
              defaultValue=""
              onChange={handleNote}
            />
          </ConfirmSection>
        </ConfirmBox>
      ) : (
        <DialogContentText>No pickup time determined yet.</DialogContentText>
      )}
    </div>
  );
}
