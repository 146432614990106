import BuyerDataUpload from "../buyerSearch";

var fData: any[] = [];
var mData: any[] = [];

const BuyerDataUploadWrapper: React.FC = () => {
  const updateFoundData = (data: any[]) => {
    fData = data;
  };

  const updateMissingData = (data: any[]) => {
    mData = data;
  };

  return (
    <BuyerDataUpload
      foundData={fData}
      missingData={mData}
      updateFoundData={updateFoundData}
      updateMissingData={updateMissingData}
    />
  );
};
export default BuyerDataUploadWrapper;
