import { ConfirmationText } from "./styles";

export default function CPopup() {
  return (
    <>
      <ConfirmationText> Offer Updated! </ConfirmationText>
      <br />
      <ConfirmationText>
        {" "}
        Once the buyer approves, you will receive an email with next steps.
      </ConfirmationText>
    </>
  );
}
