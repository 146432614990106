import { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CircularProgress,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";

import getUser from "../../Utils/getUser";

import {
  PreviousRecomandationBox,
  PreviousRecomandationText,
  NextButton,
  StyledTableContainer,
  StyledTable,
  StyledTableHeader,
  StyledTableCell,
} from "./styles";

const TrackOffers = () => {
  const navigate = useNavigate();
  const [offers, setOffers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [madeOffer, setMadeOffer] = useState(false);
  const [foundPage, setFoundPage] = useState(0);
  const [foundRowsPerPage, setFoundRowsPerPage] = useState(10);
  // const [foundPaginatedData, setFoundPaginatedData] = useState<any[]>([]);
  const [orderBy, setOrderBy] = useState("dateEnd");
  const [order, setOrder] = useState<"asc" | "desc">("desc");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_BASE_URL + "offer/get_user_offer",
          { params: { userID: getUser() } }
        );
        setOffers(response.data.offer);
        // setMadeOffer(response.data.offerFound);
        setIsLoading(false);
      } catch (error) {
        console.error("Error grabbing listing:", error);
      }
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   const startFoundIndex = foundPage * foundRowsPerPage;
  //   const endFoundIndex = startFoundIndex + foundRowsPerPage;
  //   setFoundPaginatedData(offers.slice(startFoundIndex, endFoundIndex));
  // }, [offers, foundPage, foundRowsPerPage]);

  const handleFoundChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setFoundPage(newPage);
  };

  const handleFoundChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFoundRowsPerPage(parseInt(event.target.value, 10));
    setFoundPage(0);
  };

  const handleSeeOffer = (id: string) => {
    navigate("/review-offer?o=" + id);
  };

  const sortedData = offers.sort((a, b) => {
    if (orderBy) {
      if (order === "asc") {
        return a[orderBy] < b[orderBy] ? -1 : 1;
      }
      return a[orderBy] > b[orderBy] ? -1 : 1;
    }
    return 0;
  });

  const handleRequestSort =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };

  const showStatus = (status: string) => {
    if (status === "IP") {
      return <span> In Progress </span>;
    } else if (status === "offered") {
      return <span> Offered </span>;
    } else if (status === "ACPT") {
      return <span> Accepted </span>;
    } else if (status === "ACPTN") {
      return <span> Accepted - Negotiated </span>;
    } else if (status === "REJ") {
      return <span> Rejected </span>;
    } else {
      return <span> Needs Update </span>;
    }
  };

  const showAmount = (amount: any, status: any) => {
    if (amount.length > 0) {
      const curr_amount = amount[amount.length - 1].toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
      if (status === "REJ") {
        return <span> Rejected </span>;
      } else {
        return <span> ${curr_amount}</span>;
      }
    } else {
      return <span> Pending </span>;
    }
  };

  return (
    <>
      <PreviousRecomandationBox>
        <PreviousRecomandationText>Current Offers </PreviousRecomandationText>
        {isLoading ? (
          <Card className="mt-2 p-0" sx={{ boxShadow: 0 }}>
            <CardContent className="pt-1 d-flex justify-content-center">
              <CircularProgress sx={{ color: "#41c175" }} />
            </CardContent>
          </Card>
        ) : (
          <>
            <StyledTableContainer>
              <StyledTable aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableHeader align="center">Status</StyledTableHeader>
                    <StyledTableHeader
                      align="center"
                      sortDirection={orderBy === "dateStart" ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === "dateStart"}
                        direction={orderBy === "dateStart" ? order : "asc"}
                        onClick={handleRequestSort("dateStart")}
                      >
                        Date Start
                      </TableSortLabel>
                    </StyledTableHeader>
                    <StyledTableHeader
                      align="center"
                      sortDirection={orderBy === "dateEnd" ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === "dateEnd"}
                        direction={orderBy === "dateEnd" ? order : "asc"}
                        onClick={handleRequestSort("dateEnd")}
                      >
                        Date End
                      </TableSortLabel>
                    </StyledTableHeader>
                    <StyledTableHeader align="center">
                      Offer Amount (Current)
                    </StyledTableHeader>
                    {/** 
                  <StyledTableHeader align="center" >
                    Listings
                  </StyledTableHeader>
                  */}
                    <StyledTableHeader align="center">
                      Actions
                    </StyledTableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData
                    .slice(
                      foundPage * foundRowsPerPage,
                      foundPage * foundRowsPerPage + foundRowsPerPage
                    )
                    ?.map((offer: any, index: Number) => (
                      <TableRow>
                        <StyledTableCell align="center">
                          {showStatus(offer.status)}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {moment(offer.dateStart).format("MM/DD/YYYY")}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {moment(offer.dateEnd).format("MM/DD/YYYY")}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {showAmount(offer.offerAmounts, offer.status)}
                          {/** 
                      {offer.offerAmounts.length > 0 ? "$" + offer.offerAmounts[offer.offerAmounts.length - 1].toLocaleString(undefined, { minimumFractionDigits: 2 }) : 'Pending' }
                      */}
                        </StyledTableCell>
                        {/** 
                    <StyledTableCell align="center" >
                       See Included Listings
                    </StyledTableCell>
                    */}
                        <StyledTableCell align="center">
                          <NextButton onClick={() => handleSeeOffer(offer._id)}>
                            {" "}
                            See Full Offer{" "}
                          </NextButton>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </StyledTable>
            </StyledTableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={offers.length}
              rowsPerPage={foundRowsPerPage}
              page={foundPage}
              onPageChange={handleFoundChangePage}
              onRowsPerPageChange={handleFoundChangeRowsPerPage}
              sx={{
                "& .MuiTablePagination-displayedRows": {
                  marginBottom: "0rem",
                },
                "& .MuiTablePagination-selectLabel": {
                  marginBottom: "0rem",
                },
              }}
            />
          </>
        )}
      </PreviousRecomandationBox>
    </>
  );
};
export default TrackOffers;
