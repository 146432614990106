import { useState, useRef, useEffect } from "react";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import axios from "axios";
import { toast } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import MenuItem from "@mui/material/MenuItem";
import {
  InputLabel,
  Select,
  SelectChangeEvent,
  OutlinedInput,
} from "@mui/material";

import getUser from "../../Utils/getUser";

import { ConfirmationText, ConfirmationHeader, NextButton } from "./styles";

interface Location {
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
}

interface LocationFormProps {
  onClose: () => void;
}

export default function LocationForm({ onClose }: LocationFormProps) {
  const user = getUser();
  const [location, setLocation] = useState<Location>({
    name: "",
    address: "",
    city: "",
    state: "",
    zip: "",
  });

  const dates = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [pickupSchedule, setPickupSchedule] = useState<{
    [key: string]: { start: any; end: any };
  }>({});
  const [pickupDates, setPickupDates] = useState([]);
  const sortedPickupDates = pickupDates.sort(
    (a, b) => dates.indexOf(a) - dates.indexOf(b)
  );

  const [isPickup, setIsPickup] = useState(false);

  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [canSave, setCanSave] = useState<boolean>(false);
  const autocompleteRef = useRef<any>(null);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_API_GOOGLE_PLACES_TEST || "",
    libraries: ["places"],
  });

  useEffect(() => {
    if (isLoaded) {
      const style = document.createElement("style");
      style.innerHTML = `
        .pac-container {
          z-index: 1500; /* Ensure dropdown is on top */
          position: absolute; /* Position dropdown correctly */
        }
      `;
      document.head.appendChild(style);

      // Cleanup function to remove the style when the component unmounts
      return () => {
        document.head.removeChild(style);
      };
    }
  }, [isLoaded]);

  useEffect(() => {
    // Function to validate if all selected dates have valid start and end times
    const validateCanSave = () => {
      if (isPickup && pickupDates.length === 0) {
        setCanSave(false);
        return;
      }

      for (let date of pickupDates) {
        const schedule = pickupSchedule[date];
        if (!schedule || !schedule.start || !schedule.end) {
          // If any date is missing start or end time
          setCanSave(false);
          return;
        }

        if (schedule.end.isBefore(schedule.start)) {
          // If the end time is earlier than the start time
          setCanSave(false);
          return;
        }
      }

      // All dates have valid times, enable save
      setCanSave(true);
    };

    validateCanSave();
  }, [pickupSchedule, pickupDates, isPickup]);

  const handlePlaceSelect = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      console.log(place);

      if (place) {
        const addressComponents = place.address_components || [];

        const locationDetails: Location = {
          name: place.name || "",
          address: "",
          city: "",
          state: "",
          zip: "",
        };

        const formattedAddressParts = place.formatted_address?.split(",") || [];
        console.log(formattedAddressParts);
        if (formattedAddressParts.length > 0) {
          locationDetails.address = formattedAddressParts[0]; // Assuming address is the first part
        }

        addressComponents.forEach(
          (component: google.maps.GeocoderAddressComponent) => {
            if (component.types.includes("locality")) {
              locationDetails.city = component.long_name;
            }
            if (component.types.includes("administrative_area_level_1")) {
              locationDetails.state = component.short_name;
            }
            if (component.types.includes("postal_code")) {
              locationDetails.zip = component.long_name;
            }
          }
        );

        setLocation(locationDetails);
      }
    }
  };

  const handleChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    var newDates: any = typeof value === "string" ? value.split(",") : value;

    const updatedSchedule = { ...pickupSchedule };
    const removedDates = pickupDates.filter((date) => !newDates.includes(date));
    removedDates.forEach((date) => {
      delete updatedSchedule[date];
    });

    newDates.forEach((date: any) => {
      if (!updatedSchedule[date]) {
        updatedSchedule[date] = { start: null, end: null };
      }
    });

    setPickupDates(newDates);
    setPickupSchedule(updatedSchedule);
  };

  const handleDateTimeChange = (
    date: string,
    type: "start" | "end",
    time: any
  ) => {
    setPickupSchedule((prevSchedule) => ({
      ...prevSchedule,
      [date]: {
        ...prevSchedule[date],
        [type]: time,
      },
    }));
    console.log(pickupSchedule);
  };

  if (loadError) {
    return <div>Error loading Google Maps API</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  const addLocation = async () => {
    try {
      const formattedPickupSchedule = Object.entries(pickupSchedule).map(
        ([day, times]) => ({
          day,
          start: times.start,
          end: times.end,
        })
      );
      const params = {
        location: location,
        pickup: isPickup,
        times: formattedPickupSchedule,
        userID: user,
      };
      await axios
        .post(process.env.REACT_APP_API_BASE_URL + "user/add_location", params)
        .then((response) => {
          toast.success("New Location has been added", {
            position: "top-right",
            autoClose: 1500,
          });
          onClose();
        });
    } catch (error) {
      console.error("Error Adding Location:", error);
    }
  };

  return (
    <div>
      <ConfirmationHeader>Add New Location</ConfirmationHeader>
      <div style={{ border: "1px solid #CCC", marginBottom: "20px" }}></div>
      <ConfirmationText> Search Below </ConfirmationText>

      <Autocomplete
        onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
        onPlaceChanged={handlePlaceSelect}
      >
        <input
          id="autocomplete"
          className="input-field"
          type="text"
          placeholder="Search for a place"
        />
      </Autocomplete>
      <div>
        <label>
          <input
            type="checkbox"
            checked={isEditable}
            onChange={(e) => setIsEditable(e.target.checked)}
          />
          Edit
        </label>
      </div>
      <div>
        <label>
          Name:
          <input
            name="name"
            value={location.name}
            placeholder="Name"
            onChange={(e) =>
              setLocation((prevLocation) => ({
                ...prevLocation,
                name: e.target.value,
              }))
            }
            disabled={!isEditable}
            style={{ width: "100%", maxWidth: "500px", padding: "8px" }}
          />
        </label>
        <br />
        <label>
          Address:
          <input
            name="address"
            value={location.address}
            placeholder="Street Address"
            onChange={(e) =>
              setLocation((prevLocation) => ({
                ...prevLocation,
                address: e.target.value,
              }))
            }
            disabled={!isEditable}
            style={{ width: "100%", maxWidth: "500px", padding: "8px" }}
          />
        </label>
        <br />
        <label>
          City:
          <input
            name="city"
            value={location.city}
            placeholder="City"
            onChange={(e) =>
              setLocation((prevLocation) => ({
                ...prevLocation,
                city: e.target.value,
              }))
            }
            disabled={!isEditable}
            style={{ width: "100%", maxWidth: "500px", padding: "8px" }}
          />
        </label>
        <br />
        <label>
          State:
          <input
            name="state"
            value={location.state}
            placeholder="State"
            onChange={(e) =>
              setLocation((prevLocation) => ({
                ...prevLocation,
                state: e.target.value,
              }))
            }
            disabled={!isEditable}
            style={{ width: "100%", maxWidth: "500px", padding: "8px" }}
          />
        </label>
        <br />
        <label>
          Zip Code:
          <input
            name="zip"
            value={location.zip}
            placeholder="Zip Code"
            onChange={(e) =>
              setLocation((prevLocation) => ({
                ...prevLocation,
                zip: e.target.value,
              }))
            }
            disabled={!isEditable}
            style={{ width: "100%", maxWidth: "500px", padding: "8px" }}
          />
        </label>
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={isPickup}
            onChange={(e) => setIsPickup(e.target.checked)}
          />
          Can pickup from this location
        </label>
      </div>
      {isPickup && (
        <div>
          <div>
            <InputLabel id="multiple-date-label">
              Select Pickup Dates
            </InputLabel>
            <Select
              labelId="multiple-date-label"
              id="multiple-date"
              multiple
              value={pickupDates}
              onChange={handleChange}
              input={<OutlinedInput label="Name" />}
            >
              {dates.map((date) => (
                <MenuItem key={date} value={date}>
                  {date}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div>
            {sortedPickupDates.length > 0 && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {sortedPickupDates.map((pickup) => (
                  <div key={pickup}>
                    <br />
                    {pickup}{" "}
                    <TimePicker
                      label={`${pickup} Start Time`}
                      value={pickupSchedule[pickup]?.start || null}
                      onChange={(time) =>
                        handleDateTimeChange(pickup, "start", time)
                      }
                    />
                    <TimePicker
                      label={`${pickup} End Time`}
                      value={pickupSchedule[pickup]?.end || null}
                      onChange={(time) =>
                        handleDateTimeChange(pickup, "end", time)
                      }
                    />
                  </div>
                ))}
              </LocalizationProvider>
            )}
          </div>
        </div>
      )}
      <br />
      <NextButton onClick={addLocation} disabled={!canSave}>
        Save Location
      </NextButton>
    </div>
  );
}
