import styled from "@emotion/styled";

export const DataUploadBox = styled("div")`
  max-width: 680px;
  width: 100%;
  margin: auto;
  height: 100%;
  min-height: 358px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: 768px) {
    min-height: 425px;
  }
`;

export const DataUploadText = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #171717;
  font-size: 35px;
  font-family: "ProximaNova" !important;
  font-style: normal;
  font-weight: 700;
  line-height: 124%;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

export const UploadBox = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export const ContentText = styled("div")`
  color: #343434;
  font-size: 22px;
  font-style: normal;
  font-family: "ProximaNova-Medium" !important;
  font-weight: 400;
  line-height: 27px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const ButtonBox = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-top: 76px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    width: 100%;
  }
`;

export const NextButton = styled("button")`
  background-color: #48b7a7;
  color: white;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 1px solid;
  padding: 15px 25px;
  border-radius: 5px;
  font-size: 22px;
  font-family: "ProximaNova !important";
  gap: 10px;
  max-width: 300px;
  min-width: 200px;
  width: 100%;
  height: 55px;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const UploadButton = styled("button")`
  background-color: #48b7a7;
  border: 1px solid #48b7a7;
  padding: 15px 25px;
  border-radius: 5px;
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 320px;
  width: 100%;
  height: 55px;
  cursor: pointer;
  .button-icon {
    margin-top: -7px;
  }
  .button-text {
    color: white;
    font-weight: 700;
    line-height: 1.5;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 22px;
    font-family: "ProximaNova" !important;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
  @media (max-width: 768px) {
    max-width: 768px;
  }
`;
export const FileUploadButton = styled.button`
  background-color: #48b7a7;
  opacity: ${({ disabled }) => (disabled ? "0.3" : "1")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: white;
  font-weight: 700;
  line-height: 1.5;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 1px solid;
  padding: 15px 25px;
  border-radius: 5px;
  font-size: 20px;
  font-family: "ProximaNova", sans-serif;
  gap: 10px;
  margin-top: 6px;
  display: flex;
  max-height: 40px;
  height: 100%;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 768px) {
    font-size: 18px;
  }

  &:disabled {
    background-color: #b8d9d1;
    border-color: #b8d9d1;
  }
`;

export const FileuploadBox = styled("div")`
  .file-text {
    font-size: 18px;
    font-family: "ProximaNova" !important;
    font-weight: 700;
  }
  .input-field {
    font-size: 18px;
    font-family: "ProximaNova" !important;
  }
  @media (max-width: 465px) {
    max-width: 265px;
    width: 100%;
    .input-field {
      width: 100%;
    }
  }
`;

export const FileUploadHeader = styled("div")`
  font-size: 20px;
  text-align: center;
  padding-bottom: 10px;
  font-weight: 700;
`;

export const FileUploadBox = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #ccc;
  border-radius: 15px;
  margin-bottom: 20px;
  position: relative;
`;

export const AlertBox = styled("div")`
  margin-bottom: 20px;
  .alert-text {
    font-size: 16px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
`;

export const UploadedFile = styled("div")`
  padding: 10px;
  height: 80px;
  margin: 5px;
  background: linear-gradient(rgb(238, 238, 238), rgb(221, 221, 221));
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
`;
