import getUser from "../../Utils/getUser";
import moment from "moment";
import {
  Card,
  CardContent,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { useGetExcessId } from "../../Redux/api/User";

import { getExcessobj } from "../../Utils/getexcessobj";

import {
  PreviousRecomandationBox,
  PreviousRecomandationText,
  StyledTableCell,
  StyledTableHeader,
} from "./styles";

const PreviousRecommendation = () => {
  const {
    data: { data: { excess = [] } = {} } = {},
    isLoading,
    isFetching,
  } = useGetExcessId(getUser());

  return (
    <>
      <PreviousRecomandationBox excessdata={excess?.length}>
        <PreviousRecomandationText>
          Previous Recommendations{" "}
        </PreviousRecomandationText>
        {isLoading || isFetching ? (
          <Card className="mt-2 p-0" sx={{ boxShadow: 0 }}>
            <CardContent className="pt-1 d-flex justify-content-center">
              <CircularProgress sx={{ color: "#41c175" }} />
            </CardContent>
          </Card>
        ) : (
          <TableContainer component={Paper} sx={{ width: "100%" }}>
            <Table
              sx={{ backgroundColor: "#EDEDED" }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableHeader align="center">
                    File Name
                  </StyledTableHeader>
                  <StyledTableHeader align="center">
                    Date of submission
                  </StyledTableHeader>
                  <StyledTableHeader align="center">
                    Data Upload
                  </StyledTableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {excess?.map((excessdata: any, index: Number) => (
                  <TableRow>
                    <StyledTableCell align="center">
                      {excessdata.fileName ? excessdata.fileName : "-"}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {moment(excessdata.date).format("MM/DD/YYYY")}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {getExcessobj(excessdata)}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </PreviousRecomandationBox>
    </>
  );
};
export default PreviousRecommendation;
