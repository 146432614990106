import React, { useEffect, useState } from "react";
import { TableBody, TableHead, TablePagination, TableRow } from "@mui/material";

import {
  BoldText,
  StyledTable,
  StyledTableContainer,
  StyledTableHeader,
} from "./styles";

interface DataTableProps {
  offerData: any[];
  bulk: boolean;
}

const DataTableRaw: React.FC<DataTableProps> = ({ offerData, bulk }) => {
  //console.log(offerData);
  const [foundPage, setFoundPage] = useState(0);
  const [foundRowsPerPage, setFoundRowsPerPage] = useState(10);
  const [foundPaginatedData, setFoundPaginatedData] = useState<any[]>([]);

  const headers = offerData.length > 0 ? Object.keys(offerData[0]) : [];

  useEffect(() => {
    const startFoundIndex = foundPage * foundRowsPerPage;
    const endFoundIndex = startFoundIndex + foundRowsPerPage;
    setFoundPaginatedData(offerData.slice(startFoundIndex, endFoundIndex));
  }, [offerData, foundPage, foundRowsPerPage]);

  const handleFoundChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setFoundPage(newPage);
  };

  const handleFoundChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFoundRowsPerPage(parseInt(event.target.value, 10));
    setFoundPage(0);
  };

  return (
    <div>
      <StyledTableContainer>
        <StyledTable aria-label="simple table">
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <StyledTableHeader key={header} align="center">
                  <BoldText> {header} </BoldText>
                </StyledTableHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {foundPaginatedData.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {headers.map((header) => (
                  <StyledTableHeader key={header}>
                    {header === "MFSRP" ? `$${row[header]}` : row[header]}{" "}
                  </StyledTableHeader>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={offerData.length}
        rowsPerPage={foundRowsPerPage}
        page={foundPage}
        onPageChange={handleFoundChangePage}
        onRowsPerPageChange={handleFoundChangeRowsPerPage}
        sx={{
          "& .MuiTablePagination-displayedRows": {
            marginBottom: "0rem",
          },
          "& .MuiTablePagination-selectLabel": {
            marginBottom: "0rem",
          },
        }}
      />
    </div>
  );
};

export default DataTableRaw;
