import { useState, useEffect } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";

import CPopup from "./confirmationPop";
import LocPopup from "./locationPop";

import getUser from "../../Utils/getUser";

import {
  DataPreviewBox,
  NextButton,
  ContextHeading,
  HeadingText,
  CostText,
} from "./styles";

import Point from "../../assets/images/svg/Down.svg";

interface OfferItem {
  _id: any;
  buyer: any;
  completion: any;
  listings: any;
  dateEnd: any;
  offerAmounts: any[];
  offerDates: any[];
  seller: any;
  shipping: any;
  status: any;
  pickupTime: string;
}

interface Location {
  _id: any;
  name: any;
  address: any;
  city: any;
  state: any;
  zip: any;
}

const OfferDocs = () => {
  const logged_user = getUser();
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const [item, setItem] = useState<OfferItem>({} as OfferItem);
  const [showInfo, setShowInfo] = useState(false);
  // const [madeOffer, setMadeOffer] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  // const [isBulk, setIsBulk] = useState(true);
  // const [buyerTOS, setBuyerTOS] = useState(true);
  const [isAcpt, setIsAcpt] = useState(true);
  const [weight, setWeight] = useState("");
  const [unit, setUnit] = useState("lbs");
  const [pallet, setPallet] = useState("");

  const offer = queryParameters.get("o");
  // const time = queryParameters.get("d");
  const user = queryParameters.get("u");
  const email = queryParameters.get("e");
  // const download = queryParameters.get("download");

  const [addLocation, setAddLocation] = useState(false);
  const [openLocModal, setOpenLocModal] = useState(false);

  // const [searchResult, setSearchResult] = useState<any>();

  const [locations, setLocations] = useState<Location[]>([]); // Initially, no locations
  const [selectedLocation, setSelectedLocation] = useState("");

  const [secondaryLoc, setSecondaryLoc] = useState<Location>();

  const handleLocationSelect = (event: any) => {
    const selectedValue = event.target.value;
    console.log(event);
    console.log(selectedValue);
    setSelectedLocation(selectedValue);
    if (selectedValue === "Add Location") {
      setAddLocation(true);
    } else {
      setAddLocation(false);
    }
  };

  // function onLoad(autocomplete: any) {
  //   setSearchResult(autocomplete);
  // }

  // function locationSelected() {
  //   if (searchResult) {
  //     const place = searchResult.getPlace();
  //     console.log("Search : ", place);
  //   }
  // }

  const default_location = {
    name: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    _id: "",
  };

  // const [location, setLocation] = useState<Location>({} as Location);

  const handlePaperwork = (e: any) => {
    navigate("/review-offer-paper?o=" + offer);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_BASE_URL + "offer/get_offer",
          { params: { offerID: offer, buyer: user ? user : email } }
        );
        setItem(response.data.offer);
        // setMadeOffer(response.data.offerFound);
        if (
          response.data.offer.status !== "ACPT" &&
          response.data.offer.status !== "ACPTN"
        ) {
          setIsAcpt(false);
        }

        if (!response.data.offer?.buyerTOS) {
          // setBuyerTOS(false);
        }

        if (response.data.offer?.bulk) {
          // setIsBulk(true);
        } else {
          // setIsBulk(false);
        }

        if (response.data.offer?.weight) {
          setWeight(response.data.offer.weight);
        }

        if (response.data.offer?.weightUnit) {
          setUnit(response.data.offer.weightUnit);
        }

        if (response.data.offer?.pallet) {
          setPallet(response.data.offer?.pallet);
        }

        if (response.data.offer?.sellLocation) {
          setSelectedLocation(response.data.offer?.sellLocation._id);
        }

        if (response.data.offer?.buyLocation) {
          setSecondaryLoc(response.data.offer?.buyLocation);
        }

        fetchLocations();
      } catch (error) {
        //console.error("Error grabbing listing:", error);
      }
    };
    fetchData();
  }, [offer]);

  const handleClose = () => {
    setOpenModal(false);
    window.location.reload();
  };

  const handleLocClose = () => {
    setOpenLocModal(false);
    // const all_locations = fetchLocations();
  };

  const handleLocOpen = () => {
    setOpenLocModal(true);
  };

  const fetchLocations = async () => {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL + "user/get_all_loc",
      {
        params: {
          userID: logged_user,
          offerID: offer,
          buyer: user ? user : email,
        },
      }
    );
    if (response.data.locations) {
      setLocations(response.data.locations);
      if (response.data.locations.length === 0) {
        setAddLocation(true);
      } else {
        setAddLocation(false);
      }
      return response.data.locations;
    } else {
      return [];
    }
  };

  const handleShowInfo = (e: any) => {
    setShowInfo(!showInfo);
  };

  function isEmpty(obj: any) {
    console.log(obj);
    if (obj) {
      return Object.keys(obj).length === 0;
    } else {
      return true;
    }
  }

  const formatDateTime = (dateString: string) => {
    return dayjs(dateString)
      .tz(dayjs.tz.guess()) // Converts to the user's local timezone
      .format("MMMM DD, YYYY, hh:mm A"); // Formatting to Month, Day, Year, Hour:Minute AM/PM
  };

  const showPickup = () => {
    if (item.pickupTime && item.pickupTime !== "") {
      const formattedDateTime = formatDateTime(item.pickupTime);
      return (
        <div>
          <CostText> Pickup Information </CostText>
          <div> Buyer has scheduled pickup on {formattedDateTime}</div>
        </div>
      );
    }
  };

  const showOptions = () => {
    if (isAcpt) {
      return (
        <div>
          <Dialog
            open={openModal}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
              style: {
                overflowX: "hidden",
                maxWidth: "665px",
                width: "100%",
              },
            }}
          >
            <DialogTitle>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 0,
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <CPopup />
            </DialogContent>
          </Dialog>
          <Dialog
            open={openLocModal}
            onClose={handleLocClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
              style: {
                overflowX: "hidden",
                maxWidth: "665px",
                width: "100%",
              },
            }}
          >
            <DialogTitle>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleLocClose}
                aria-label="close"
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 0,
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <LocPopup onClose={handleLocClose} />
            </DialogContent>
          </Dialog>
          Pallet Count:
          <input
            type="number"
            value={pallet}
            onChange={(e) => setPallet(e.target.value)}
            placeholder="Pallet Count"
            style={{
              width: "100%",
              padding: "10px",
              margin: "10px 0",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
          />
          Weight:
          <input
            type="number"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            placeholder="Weight"
            style={{
              width: "100%",
              padding: "10px",
              margin: "10px 0",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
          />
          Unit:
          <input
            type="text"
            value={unit}
            onChange={(e) => setUnit(e.target.value)}
            placeholder="Unit"
            style={{
              width: "100%",
              padding: "10px",
              margin: "10px 0",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
          />
          <label htmlFor="location-select">Choose a location:</label>
          <select
            id="location-select"
            value={selectedLocation}
            onChange={handleLocationSelect}
            style={{
              width: "100%",
              padding: "10px",
              margin: "10px 0",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
          >
            {locations.length === 0 ? (
              <option value="Add Location">Add Location</option>
            ) : (
              <>
                {locations.map((location, index) => (
                  <option key={index} value={location?._id}>
                    {location?.name} - {location?.address}
                  </option>
                ))}
                <option value="Add Location">Add Location</option>
              </>
            )}
          </select>
          {addLocation && (
            <NextButton onClick={handleLocOpen}>Add New Location</NextButton>
          )}
          <NextButton onClick={updateInfo}>Update Offer Data</NextButton>
          <NextButton onClick={handlePaperwork}>
            Check Paperwork Items
          </NextButton>
        </div>
      );
    }
  };

  const updateInfo = async () => {
    var locationSelected = false;
    var new_location = default_location;

    if (selectedLocation === "Add Location") {
      new_location = default_location;
    } else {
      for (var location of locations) {
        if (selectedLocation === String(location._id)) {
          new_location = location;
          locationSelected = true;
        }
      }
    }
    try {
      const params = {
        pallet: pallet,
        weight: weight,
        unit: unit,
        offerID: offer,
        location: new_location,
        addLocation: locationSelected,
        type: "supplier",
      };
      await axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "offer/update_offer_info",
          params
        )
        .then((response) => {
          setOpenModal(true);
        });
    } catch (error) {
      console.error("Error Submitting Order:", error);
    }
  };

  return (
    <div>
      <ContextHeading>
        {" "}
        <HeadingText> Supplier Next Steps </HeadingText>{" "}
      </ContextHeading>

      <DataPreviewBox>
        <div>{showPickup()}</div>
      </DataPreviewBox>

      <DataPreviewBox>
        <div> {showOptions()} </div>
      </DataPreviewBox>

      <CostText onClick={handleShowInfo}>
        {" "}
        Show Additional Information <img src={Point} alt="point" />{" "}
      </CostText>
      {showInfo && (
        <div>
          {isEmpty(secondaryLoc) ? (
            <DataPreviewBox>
              Waiting on buyer to add the delievery location
            </DataPreviewBox>
          ) : (
            <DataPreviewBox>
              <div> Delievery Location: </div>
              <label>
                Address:
                <input
                  name="address"
                  value={secondaryLoc?.address}
                  placeholder="Street Address"
                  disabled={true}
                  style={{ width: "100%", maxWidth: "500px", padding: "8px" }}
                />
              </label>
              <br />
              <label>
                City:
                <input
                  name="city"
                  value={secondaryLoc?.city}
                  placeholder="City"
                  disabled={true}
                  style={{ width: "100%", maxWidth: "500px", padding: "8px" }}
                />
              </label>
              <br />
              <label>
                State:
                <input
                  name="state"
                  value={secondaryLoc?.state}
                  placeholder="State"
                  disabled={true}
                  style={{ width: "100%", maxWidth: "500px", padding: "8px" }}
                />
              </label>
              <br />
              <label>
                Zip Code:
                <input
                  name="zip"
                  value={secondaryLoc?.zip}
                  placeholder="Zip Code"
                  disabled={true}
                  style={{ width: "100%", maxWidth: "500px", padding: "8px" }}
                />
              </label>
            </DataPreviewBox>
          )}
        </div>
      )}
    </div>
  );
};

export default OfferDocs;
