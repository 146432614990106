import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { StyledTableCell, StyledTableHeader } from "./styles";

const createData = (
  productId: string,
  size: string,
  unit: number,
  date: string
) => {
  return { productId, size, unit, date };
};

const previewData = [
  createData("GA-23189", "S", 48, " 07 / 03 / 2021"),
  createData("GA-23190", "M", 35, " 07 / 03 / 2021"),
  createData("GA-23191", "L", 68, " 07 / 03 / 2021"),
];

const DataTable = () => {
  return (
    <TableContainer component={Paper} sx={{ width: "100%" }}>
      <Table sx={{ backgroundColor: "#EDEDED" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableHeader align="center">Product ID</StyledTableHeader>
            <StyledTableHeader align="center">Size</StyledTableHeader>
            <StyledTableHeader align="center">
              Number of Units
            </StyledTableHeader>
            <StyledTableHeader align="center">Date Acquired</StyledTableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {previewData.map((row) => (
            <TableRow key={row.productId}>
              <StyledTableCell align="center">{row.productId}</StyledTableCell>
              <StyledTableCell align="center">{row.size}</StyledTableCell>
              <StyledTableCell align="center">{row.unit}</StyledTableCell>
              <StyledTableCell align="center">{row.date}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default DataTable;
