import { styled } from "@mui/material";

export const BusinessInfoBox = styled("div")`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  .business-form {
    width: 305px;
  }
`;
