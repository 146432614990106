import { useNavigate } from "react-router-dom";

import { previousRecommendation } from "../../Routes/constants";

import {
  ConfirmationBox,
  ConfirmationHeaderText,
  ContentText,
  NextButton,
} from "./styles";

const ConfirmationPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <ConfirmationBox>
        <ConfirmationHeaderText>
          Thanks! Schedule a meeting with this {""}
          <a href="https://calendly.com/sotira-qualification-call/15min?back=1&month=2023-12">
            {" "}
            link{" "}
          </a>
          {""} or We’ll reach out in 24 hours with next steps.
        </ConfirmationHeaderText>
        <ContentText>
          Feel free to email {""}
          <a href="mailto:support@sotira.co">support@sotira.co </a>
          {""} or call{""}
          <span style={{ color: "#0D6EFD" }}> (650) 485-3603</span>
          {""} if you have any questions.
        </ContentText>
        <NextButton onClick={() => navigate(previousRecommendation)}>
          Next
        </NextButton>
      </ConfirmationBox>
    </>
  );
};
export default ConfirmationPage;
