import * as yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { Box, CircularProgress } from "@mui/material";

import {
  usePostAddBusinessInfo,
  useLazygetUserNameQuery,
} from "../../Redux/api/User";
import { authUser } from "../../Redux/auth/reducer";

import { homeRoute } from "../../Routes/constants";

import getUser from "../../Utils/getUser";

import { BusinessInfoBox } from "./styles";
import { StyleButton } from "../../Common/styles";

const BusinessInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addBusinessInfo, { isLoading: isBusiInfoAdding }]: any =
    usePostAddBusinessInfo();
  const [getuserName]: any = useLazygetUserNameQuery();

  const validationSchema = yup.object({
    busiName: yup.string().required("Business name is required"),
    busiLink: yup
      .string()
      .url("Please enter valid website link")
      .required("Business website link is required"),
  });

  const formik = useFormik({
    initialValues: {
      busiName: "",
      busiLink: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const { busiName, busiLink } = values;

      try {
        const payload = {
          company: busiName,
          link: busiLink,
          userID: getUser(),
        };
        await addBusinessInfo(payload);
        const { data } = await getuserName(getUser());
        localStorage.setItem("FirstName", data?.firstName);
        localStorage.setItem("LastName", data?.lastName);
        dispatch(authUser({ isLoggedIn: true }));
        navigate(homeRoute);
      } catch (err) {
        toast.error("Try again, information was not processed properly");
      }
    },
  });

  const { values, handleSubmit, handleChange, handleBlur, errors, touched } =
    formik;

  return (
    <>
      <BusinessInfoBox>
        <Form className="business-form" onSubmit={handleSubmit}>
          <Form.Group className="form-field" style={{ paddingBottom: "20px" }}>
            <Form.Label className="title">Business Name</Form.Label>
            <Form.Control
              autoFocus
              type="businessName"
              name="busiName"
              value={values.busiName}
              placeholder="Enter business name"
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.busiName && !!errors.busiName}
            />
            {touched.busiName && errors.busiName && (
              <Form.Control.Feedback type="invalid">
                {errors.busiName}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="form-field" style={{ paddingBottom: "20px" }}>
            <Form.Label className="title">Business Website</Form.Label>
            <Form.Control
              autoFocus
              type="businessWebsite"
              name="busiLink"
              value={values.busiLink}
              placeholder="Enter website name"
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.busiLink && !!errors.busiLink}
            />
            {touched.busiLink && errors.busiLink && (
              <Form.Control.Feedback type="invalid">
                {errors.busiLink}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Box>
            <StyleButton
              disabled={isBusiInfoAdding}
              endIcon={
                isBusiInfoAdding ? (
                  <CircularProgress size={18} sx={{ color: "#48b7a7" }} />
                ) : null
              }
            >
              Add Business information
            </StyleButton>
          </Box>
        </Form>
      </BusinessInfoBox>
    </>
  );
};
export default BusinessInfo;
