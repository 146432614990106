import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";

import { additionaloptionRoutes } from "../../Routes/constants";

import {
  OptionsBox,
  OffloadingOptionsText,
  Offloadingheader,
  OptionsFrame,
  StartButton,
  OptionsFrameBox,
  OptionsFrameHeader,
  OptionsFrameContent,
  EstimatedText,
  AdditionalOptionButton,
  OptionsImages,
  AnalysisButton,
} from "./styles";

import DonateIcon from "../../assets/images/svg/Donate.svg";
import PoshmarkIcon from "../../assets/images/svg/Poshmark.svg";
import Private_buyerIcon from "../../assets/images/svg/Private_buyer.svg";

const OffloadingOptions = () => {
  const navigate = useNavigate();

  const handleOffloadingOptions = () => {
    navigate(additionaloptionRoutes);
  };
  return (
    <>
      <OptionsBox>
        <Offloadingheader>
          <OffloadingOptionsText>Offloading Options</OffloadingOptionsText>
          <div className="option-text">
            Here’s what we recommend for your business.
          </div>
        </Offloadingheader>
        <OptionsFrameBox>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, sm: 12, md: 4, lg: 4 }}>
              <OptionsFrame>
                <OptionsImages>
                  <img src={DonateIcon} alt="DonateImage" />
                </OptionsImages>
                <OptionsFrameHeader>Donate to Goodwill</OptionsFrameHeader>
                <OptionsFrameContent>
                  We’ve found a few charities that will accept your donation
                  within the week.
                </OptionsFrameContent>
                <EstimatedText>
                  Estimated Tax Write-Off: <span>$40,000</span>
                </EstimatedText>
                <StartButton>Start</StartButton>
              </OptionsFrame>
            </Grid>
            <Grid size={{ xs: 12, sm: 12, md: 4, lg: 4 }}>
              <OptionsFrame>
                <OptionsImages>
                  <img src={PoshmarkIcon} alt="PoshmarkImage" />
                </OptionsImages>
                <OptionsFrameHeader>Sell on Poshmark</OptionsFrameHeader>
                <OptionsFrameContent>
                  Given the details of your excess shoes, we suggest cross
                  listing across the following platforms. We’ve calculated
                  recommended pricing for you.
                </OptionsFrameContent>
                <EstimatedText>
                  Estimated Profit: <span>$25.65</span>
                  {""}per unit
                </EstimatedText>
                <StartButton>Start</StartButton>
              </OptionsFrame>
            </Grid>
            <Grid size={{ xs: 12, sm: 12, md: 4, lg: 4 }}>
              <OptionsFrame>
                <OptionsImages>
                  <img src={Private_buyerIcon} alt="PrivatebuyerImage" />
                </OptionsImages>
                <OptionsFrameHeader>Find a Private Buyer</OptionsFrameHeader>
                <OptionsFrameContent>
                  We’ve found a few buyers who would purchase your shoes in bulk
                  and satisfy the restrictions you set. (Domestic)
                </OptionsFrameContent>
                <EstimatedText>
                  Estimated Profit: <span>$5,500 </span>
                  {""}per pallet
                </EstimatedText>
                <StartButton>Start</StartButton>
              </OptionsFrame>
            </Grid>
          </Grid>
        </OptionsFrameBox>
      </OptionsBox>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          paddingBottom: "20px",
        }}
      >
        <AdditionalOptionButton onClick={handleOffloadingOptions}>
          Additional Options
        </AdditionalOptionButton>
        <AnalysisButton>Analysis</AnalysisButton>
      </div>
    </>
  );
};
export default OffloadingOptions;
