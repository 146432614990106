import { ConfirmationText } from "./styles";

export default function APopup() {
  return (
    <>
      <ConfirmationText> Offer Approved </ConfirmationText>
      <br />
      <ConfirmationText>
        {" "}
        You will receive an email with next steps.
      </ConfirmationText>
    </>
  );
}
