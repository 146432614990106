import styled from "@emotion/styled";
import { Table, TableCell } from "@mui/material";

export const PreviousRecomandationBox = styled("div")`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PreviousRecomandationText = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #171717;
  font-size: 35px;
  padding-bottom: 20px;
  font-family: "ProximaNova" !important;
  font-style: normal;
  font-weight: 700;
  line-height: 124%;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

export const NextButton = styled("button")`
  background-color: #48b7a7;
  color: white;
  font-weight: 700;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 1px solid;
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 16px;
  font-family: "ProximaNova" !important;
  gap: 10px;
  width: 100%;
  margin-top: 5%;
  max-height: 55px;
  height: 100%;
  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const StyledTableHeader = styled(TableCell)({
  fontSize: "14px",
  fontWeight: 700,
  color: "#000",
  fontFamily: "ProximaNova, sans-serif",
  borderRight: "1px solid #7C7C7C",
  borderTop: "1px solid #7C7C7C",
  borderBottom: "none",
  "&:last-child": {
    borderRight: "none",
  },
});

export const StyledTableCell = styled(TableCell)({
  fontSize: "14px",
  fontWeight: 400,
  color: "#000",
  fontFamily: "ProximaNova, sans-serif",
  borderRight: "1px solid #7C7C7C",
  borderTop: "1px solid #7C7C7C",
  borderBottom: "none",
  "&:last-child": {
    borderRight: "none",
  },
});

export const StyledTable = styled(Table)({
  backgroundColor: "#fcfcfc",
  borderRadius: "5px !important",
  boxShadow: "0px 3px 15px 5px rgba(0,0,0,.1) !important",
});

export const StyledTableContainer = styled(Table)({
  width: "100%",
  borderRadius: "5px !important",
  boxShadow: "0px 3px 15px 5px rgba(0,0,0,.1) !important",
  marginTop: "20px",
});
